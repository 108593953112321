import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AxiosInstance from '../../utils/axios';

export const uploadFile = createAsyncThunk('uploadFile', async (data) => {
  try {
    const response = await AxiosInstance.post(`/file/upload`, data);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response?.data?.message || error.response?.data?.detail;
    } else {
      throw error;
    }
  }
});

const uploadFileSlice = createSlice({
  name: 'uploadFile',
  initialState: {
    isLoading: false,
    uploadFileData: null,
    isError: false,
    errorMessage: ''
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadFile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(uploadFile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.uploadFileData = action.payload;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(uploadFile.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action?.error?.message || action?.error?.detail;
      });
  }
});

export default uploadFileSlice.reducer;
