import React from 'react';
import { Spin } from 'antd';
import './loader.scss';

const Loader = () => {
    return (
        <div className="overlay-loader">
            <Spin size="default" />
        </div>
    );
};

export default Loader;
