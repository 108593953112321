import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../utils/axios";

export const settingBanIp = createAsyncThunk("settingBanIp", async (data) => {
  try {
    const response = await AxiosInstance.post(`/setting/add-ip`, data);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw error;
    }
  }
});

const settingBanIpSlice = createSlice({
  name: "settingBanIp",
  initialState: {
    isLoading: false,
    settingBanIpData: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: (builder) => {
    builder
      .addCase(settingBanIp.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(settingBanIp.fulfilled, (state, action) => {
        state.isLoading = false;
        state.settingBanIpData = action.payload;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(settingBanIp.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.error.message;
      });
  },
});

export default settingBanIpSlice.reducer;
