import React, { useState } from 'react';
import './dashboard.scss';
import {
  compress_pdf_icon,
  compress_pdf_right_icon,
  edit_pdf_icon,
  edit_pdf_right_icon,
  merge_pgf_icon,
  merge_pgf_right_icon,
  protect_pdf_icon,
  protect_pdf_right_icon,
  search_vector,
  sign_pdf_icon,
  sign_pdf_right_icon,
  split_pdf_icon,
  split_pdf_right_icon,
  upload_icon,
  rotate_pdf_icon,
  remove_pdf_icon,
  extract_pdf_icon,
  word_pdf_icon,
  to_word_pdf_icon,
  unlock_pdf_icon,
  remove_pdf_right_icon,
  extract_pdf_right_icon,
  word_pdf_right_icon,
  to_word_pdf_right_icon,
  unlock_pdf_right_icon,
  rotate_pdf_right_icon,
  convert_icon,
  download_button_icon,
  delete_button_icon,
  format_icon,
  download_file_icon,
  delete_file_icon,
  more_icon
} from '../../assets/images/dashboard';
import FileConvert from '../../components/partials/homeComponent/FilesConvert';
import { Dropdown, Menu, Table } from 'antd';
import { dashboarditems, formatFileSize } from '../../utils/commonUtils';
import Notify from '../../components/common/notify';
import { defaultStyles, FileIcon } from 'react-file-icon';
import { ConverterDropdown } from '../../components/partials/homeComponent/FilesConvert/converterDropdown';
import strings from '../../utils/strings.json';
import Modal from '../../components/common/Modal/modal';

const DashboardComponent = ({
  data,
  loading,
  error,
  page,
  limit,
  onPaginationChange,
  onSortChange,
  onSearch,
  search,
  onDeleteFiles,
  onFileMetadata,
  metadata,
  onReconvertFile,
  setisConvert,
  isLoading
}) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [openDropdowns, setOpenDropdowns] = useState({});
  const [convertOptions, setConvertOptions] = useState();
  const [isShowConvertPopup, setIsShowConvertPopup] = useState(false);
  const option = [
    {
      id: 1,
      image: format_icon,
      name: 'Convert File Format'
    },
    {
      id: 2,
      image: download_file_icon,
      name: 'Download'
    },
    {
      id: 3,
      image: delete_file_icon,
      name: 'Delete'
    }
  ];

  const fileOption = (
    <Menu>
      {option.map((item) => (
        <Menu.Item key={item.id}>
          <div className='upload-files-option'>
            <img src={item.image} alt={item.name} style={{ width: 25 }} />
            <span>{item.name}</span>
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );

  const menu = (
    <Menu>
      {dashboarditems.map((item) => (
        <Menu.Item key={item.id}>
          <div className='upload-files-menu'>
            <img src={item.image} alt={item.name} style={{ width: 32 }} />
            <span>{item.name}</span>
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );
  const handleSelectConvertTo = async (fileId, convertToOption) => {
    try {
      // Update only the specific file's convert option
      setConvertOptions((prevOptions) => ({
        ...prevOptions,
        [fileId]: convertToOption?.toLowerCase()
      }));
      const updatedFiles = selectedFiles?.map((file) => {
        if (file.id === fileId) {
          return { ...file, convertedTo: convertToOption?.toLowerCase() };
        }
        return file;
      });
      const fileConversions = [{ id: fileId, convertTo: convertToOption }];
      const reconvertResponse = await onReconvertFile(fileConversions);
      // Check if response indicates success
      if (reconvertResponse.type === 'file/reconvertFiles/fulfilled') {
        setisConvert(true);
        setSelectedFiles(updatedFiles);
      }
    } catch (error) {
      console.error('Error during reconversion:', error);
    }
    setOpenDropdowns((prevState) => ({
      ...prevState,
      [fileId]: false
    }));
  };
  const handleReconvert = () => {
    if (selectedFiles.length > 0) {
      setIsShowConvertPopup(true);
    } else {
      Notify('error', strings.NO_FILE_SELECTED, '');
    }
  };
  const columns = [
    {
      title: 'File Type',
      dataIndex: 'fileType',
      key: 'fileType'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true, // Enable sorting
      onHeaderCell: () => ({
        onClick: () => onSortChange('name') // Toggle sort on header click
      })
    },
    {
      title: 'Size',
      dataIndex: 'size'
    },
    {
      title: () => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '15px',
            justifyContent: 'end'
          }}
        >
          <button className='document-default-btn convert-btn' onClick={() => handleReconvert()}>
            <img src={convert_icon} alt='Convert Icon' />
            Convert
          </button>
          <button className='document-default-btn download-btn' onClick={() => onDownload()}>
            <img src={download_button_icon} alt='Download Button Icon' />
            Download
          </button>
          <button className='document-default-btn delete-btn' onClick={() => handleDeleteSelectedFiles()}>
            <img src={delete_button_icon} alt='Delete Button Icon' />
            Delete
          </button>
        </div>
      ),
      render: (_, record) => (
        <div
          className=''
          style={{
            display: 'flex',
            alignContent: 'center',
            gap: '30px',
            justifyContent: 'end',
            flexDirection: 'row'
          }}
        >
          <div
            className='file-option'
            style={{
              display: 'flex',
              alignContent: 'center',
              gap: '30px',
              justifyContent: 'end'
            }}
          >
            <div
              className='formate-info'
              onClick={() => {
                onFileMetadata(record.convertForm);
                toggleDropdown(record.key, record.convertForm);
              }}
            >
              <img src={format_icon} alt='Format Icon' />
              {convertOptions ? convertOptions[record?.key] : 'Convert File Format'}
            </div>
            <button className='download-file-btn' onClick={() => onDownload([record.exportFileUrl])}>
              <img src={download_file_icon} alt='Download File Icon' />
            </button>
            <button className='delete-file-btn' onClick={() => handleDeleteSelectedFiles([record.key])}>
              <img src={delete_file_icon} alt='Delete File Icon' />
            </button>
          </div>
          {openDropdowns[record.key] && (
            <ConverterDropdown
              setisOpen={() => toggleDropdown(record.key)}
              onSelectConvertTo={(option) => handleSelectConvertTo(record.key, option)}
              metadata={metadata}
              isLoading={isLoading}
            />
          )}
          <div className='more-option'>
            <Dropdown
              overlay={fileOption}
              trigger={['click']}
              placement='bottomRight'
              overlayclassNameName=' dashboard-header-menu dashboard-option-menu'
            >
              <img src={more_icon} alt='More' onClick={(e) => e.preventDefault()} />
            </Dropdown>
          </div>
        </div>
      )
    }
  ];
  const handleDeleteSelectedFiles = (deleteFile) => {
    if (selectedFiles.length > 0) {
      const fileIds = selectedFiles.map((file) => file.key); // Assuming key is the ID
      onDeleteFiles(fileIds); // Call the delete function with selected IDs
    } else if (deleteFile) {
      onDeleteFiles(deleteFile);
    } else {
      Notify('error', strings.NO_FILE_SELECTED, '');
    }
  };
  // eslint-disable-next-line no-unused-vars
  const onDownload = (urls, index) => {
    const urlsToDownload = urls || selectedFiles.map((file) => file.exportFileUrl); // Use selected files if no URL array is provided
    if (urlsToDownload && urlsToDownload.length > 0) {
      urlsToDownload.forEach((url) => {
        const link = document.createElement('a');
        link.href = url;
        link.download = ''; // Server handles the filename
        link.target = '_self';
        document.body.appendChild(link);
        link.click();
        Notify('success', strings.DOWNLOAD_SUCCESS_MSG, '');
        document.body.removeChild(link);
      });
    } else {
      Notify('error', strings.FILE_SELECT_ERROR, '');
    }
  };

  const paginationProps = {
    current: page,
    pageSize: limit,
    total: data?.data?.metaData.total || 0,
    onChange: (page) => onPaginationChange(page)
  };
  const userListData = data?.data?.files?.map((item) => ({
    key: item._id,
    fileType: (
      <div style={{ width: '40px', height: '40px' }}>
        <FileIcon
          extension={item.convertedFileType}
          {...defaultStyles[item.convertedFileType]}
          style={{ width: '100%', height: '100%' }}
        />{' '}
      </div>
    ),
    name: item.convertedFileName,
    size: formatFileSize(item.originalFileSize),
    exportFileUrl: item.exportFileUrl,
    convertForm: item.convertedFileType
  }));

  const toggleDropdown = (fileName) => {
    setOpenDropdowns((prevState) => ({
      ...prevState,
      [fileName]: !prevState[fileName]
    }));
  };
  
  const convertColumns = [
    {
      title: 'File Type',
      dataIndex: 'fileType',
      key: 'fileType'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true, // Enable sorting
      onHeaderCell: () => ({
        onClick: () => onSortChange('name') // Toggle sort on header click
      })
    },
    {
      title: 'Size',
      dataIndex: 'size'
    },
    {
      render: (_, record) => (
        <>
          <div
            className=''
            style={{
              display: 'flex',
              alignContent: 'center',
              gap: '30px',
              justifyContent: 'end',
              flexDirection: 'row'
            }}
          >
            <div
              className='file-option'
              style={{
                display: 'flex',
                alignContent: 'center',
                gap: '30px',
                justifyContent: 'end'
              }}
            >
              <div
                className='formate-info'
                onClick={() => {
                  onFileMetadata(record.convertForm);
                  toggleDropdown(record.key, record.convertForm);
                }}
              >
                <img src={format_icon} alt='Format Icon' />
                {convertOptions ? convertOptions[record?.key] : 'Convert File Format'}
                {console.log('1229938', convertOptions)}
              </div>
            </div>
          {openDropdowns[record.key] && (
            <ConverterDropdown
              setisOpen={() => toggleDropdown(record.key)}
              onSelectConvertTo={(option) => handleSelectConvertTo(record.key, option)}
              metadata={metadata}
              isLoading={isLoading}
            />
          )}
            <div className='more-option'>
              <Dropdown
                overlay={fileOption}
                trigger={['click']}
                placement='bottomRight'
                overlayclassNameName=' dashboard-header-menu dashboard-option-menu'
              >
                <img src={more_icon} alt='More' onClick={(e) => e.preventDefault()} />
              </Dropdown>
            </div>
          </div>
        </>
        
      )
    }
  ];
  const TableFooter = (selected) => (
    <div style={{ textAlign: 'right', marginTop: '16px' }}>
      <button type='primary' onClick={() => console.log('Convert button clicked', selected)}>
        Convert
      </button>
    </div>
  );
  return (
    <>
      {/* <!-- Your Documents Start --> */}
      <section className='your_documents_sec'>
        <div className='main_container'>
          <div className='main_row'>
            <div className='main_col title_dock1'>
              <div className='section_title'>
                <h1>Your Documents</h1>
              </div>
            </div>
            <div className='main_col title_dock2'>
              <div className='search_bar'>
                <div className='file_search'>
                  <input
                    type='text'
                    name='Search'
                    value={search}
                    placeholder='Search...'
                    className='form-control'
                    onChange={(e) => onSearch(e.target.value)}
                  />
                  <div className='search-img'>
                    <img src={search_vector} alt='Search Icon' />
                  </div>
                </div>
              </div>
            </div>
            <div className='main_col title_dock3'>
              <Dropdown
                overlay={menu}
                trigger={['click']}
                placement='bottomRight'
                overlayclassNameName='upload-files-drop-box'
              >
                <div onClick={(e) => e.preventDefault()}>
                  <div className='upload_btn'>
                    <a href='#'>
                      <img src={upload_icon} alt='Upload Icon' className='upload-files-icon' />
                      Upload File
                    </a>
                  </div>
                </div>
              </Dropdown>
            </div>
          </div>
          <Table
            rowSelection={{
              type: 'checkbox',
              onChange: (_, selectedRows) => setSelectedFiles(selectedRows)
            }}
            columns={columns}
            dataSource={userListData}
            loading={loading}
            pagination={paginationProps}
            rowKey='key'
          />
          {error && <p>Error: {error}</p>}
        </div>
      </section>
      {isShowConvertPopup && selectedFiles && (
        <Modal
          isOpen={isShowConvertPopup}
          onOk={isShowConvertPopup}
          onClose={()=>setIsShowConvertPopup(false)
          }
          footer={null} // Remove default footer
          className='modal-content urL-modal'
        >
          <Table
            columns={convertColumns}
            dataSource={selectedFiles}
            loading={loading}
            pagination={false}
            rowKey='key'
            footer={(selected) => <TableFooter selected={selected} />}
          />
        </Modal>
      )}
      {/* <!-- Your Documents End -->
	<!-- Files Convert Start --> */}
      <FileConvert />
      {/* <!-- Files Convert End -->
	<!-- Work Type Start --> */}
      <section className='work_type_sec'>
        <div className='main_container'>
          <div className='work_box_main'>
            <div className='work_box work_box1'>
              <div className='work_detl'>
                <div className='work_icon'>
                  <img src={compress_pdf_icon} alt='Compress PDF' />
                </div>
                <p>Compress PDF</p>
                <div className='work_arrow'>
                  <img src={compress_pdf_right_icon} alt='Right Icon' />
                </div>
              </div>
            </div>
            <div className='work_box work_box2'>
              <div className='work_detl'>
                <div className='work_icon'>
                  <img src={merge_pgf_icon} alt='Merge PDF' />
                </div>
                <p>Merge PDF</p>
                <div className='work_arrow'>
                  <img src={merge_pgf_right_icon} alt='Right Icon' />
                </div>
              </div>
            </div>
            <div className='work_box work_box3'>
              <div className='work_detl'>
                <div className='work_icon'>
                  <img src={split_pdf_icon} alt='Split PDF' />
                </div>
                <p>Split PDF</p>
                <div className='work_arrow'>
                  <img src={split_pdf_right_icon} alt='Right Icon' />
                </div>
              </div>
            </div>
            <div className='work_box work_box4'>
              <div className='work_detl'>
                <div className='work_icon'>
                  <img src={sign_pdf_icon} alt='Sign PDF' />
                </div>
                <p>Sign PDF</p>
                <div className='work_arrow'>
                  <img src={sign_pdf_right_icon} alt='Right Icon' />
                </div>
              </div>
            </div>
            <div className='work_box work_box5'>
              <div className='work_detl'>
                <div className='work_icon'>
                  <img src={protect_pdf_icon} alt='Protect PDF' />
                </div>
                <p>Protect PDF</p>
                <div className='work_arrow'>
                  <img src={protect_pdf_right_icon} alt='Right Icon' />
                </div>
              </div>
            </div>
            <div className='work_box work_box6'>
              <div className='work_detl'>
                <div className='work_icon'>
                  <img src={edit_pdf_icon} alt='Edit a PDF' />
                </div>
                <p>Edit a PDF</p>
                <div className='work_arrow'>
                  <img src={edit_pdf_right_icon} alt='Right Icon' />
                </div>
              </div>
            </div>
            <div className='work_box work_box7'>
              <div className='work_detl'>
                <div className='work_icon'>
                  <img src={rotate_pdf_icon} alt='Rotate' />
                </div>
                <p>Rotate</p>
                <div className='work_arrow'>
                  <img src={rotate_pdf_right_icon} alt='Right Icon' />
                </div>
              </div>
            </div>
            <div className='work_box work_box8'>
              <div className='work_detl'>
                <div className='work_icon'>
                  <img src={remove_pdf_icon} alt='Remove Pages' />
                </div>
                <p>Remove Pages</p>
                <div className='work_arrow'>
                  <img src={remove_pdf_right_icon} alt='Right Icon' />
                </div>
              </div>
            </div>
            <div className='work_box work_box9'>
              <div className='work_detl'>
                <div className='work_icon'>
                  <img src={extract_pdf_icon} alt='Extract PDF' />
                </div>
                <p>Extract PDF</p>
                <div className='work_arrow'>
                  <img src={extract_pdf_right_icon} alt='Right Icon' />
                </div>
              </div>
            </div>
            <div className='work_box work_box10'>
              <div className='work_detl'>
                <div className='work_icon'>
                  <img src={word_pdf_icon} alt='Word to PDF' />
                </div>
                <p>Word to PDF</p>
                <div className='work_arrow'>
                  <img src={word_pdf_right_icon} alt='Right Icon' />
                </div>
              </div>
            </div>
            <div className='work_box work_box11'>
              <div className='work_detl'>
                <div className='work_icon'>
                  <img src={to_word_pdf_icon} alt='PDF to Word' />
                </div>
                <p>PDF to Word</p>
                <div className='work_arrow'>
                  <img src={to_word_pdf_right_icon} alt='Right Icon' />
                </div>
              </div>
            </div>
            <div className='work_box work_box12'>
              <div className='work_detl'>
                <div className='work_icon'>
                  <img src={unlock_pdf_icon} alt='Unlock PDF' />
                </div>
                <p>Unlock PDF</p>
                <div className='work_arrow'>
                  <img src={unlock_pdf_right_icon} alt='Right Icon' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Work Type End --> */}
    </>
  );
};

export default DashboardComponent;
