import React, { useState } from 'react';
import './dashboardHeader.scss';
import { Link } from 'react-router-dom';
import { header_logo } from '../../../assets/images/header';
import { Dropdown, Space } from 'antd';
import { HiMenu } from 'react-icons/hi';
import { Cookies, useCookies } from 'react-cookie';
import { useSession } from '../../../context/SessionContext';
const DashboardHeader = () => {
  const cookie = new Cookies();
  const [lang] = useState(cookie.get('lang') || 'en');
  const [, , removeToken] = useCookies(["token"]);
  const [, , removeRole] = useCookies(["role"]);
  const { generateNewSessionId } = useSession();
  const handleLogout = () => {
    removeToken(["token"]);
    removeRole(["role"]);
    const cookieKeys = Array.isArray(cookie.getAll())
      ? cookie.getAll()
      : Object.keys(cookie.getAll());
    cookieKeys.forEach((key) => {
      if (key !== "currency") {
        cookie.remove(key, { path: "/" });
      }
    });
    generateNewSessionId();
  };
  const items = [
    {
      label: (
        <Link to={`/${lang}/dashboard`}>
          <div className='mobile-menu-text'>{'Dashboard'}</div>
        </Link>
      ),
      key: '0'
    },
    {
      label: (
        <Link to={`/${lang}/account`}>
          <div className='mobile-menu-text'>{'Account'}</div>
        </Link>
      ),
      key: '1'
    },
    {
      label: (
        <Link to={`/${lang}/`} onClick={handleLogout}>
          <div className='mobile-menu-text'>{'Log Out'}</div>
        </Link>
      ),
      key: '3'
    }
  ];
  return (
    <>
      <header className='site-header'>
        <div className='main_container'>
          <div className='Header-main'>
            <div className='header-logo'>
              <Link to={`/${lang}/dashboard`}>
                <img src={header_logo} alt='main logo' loading='lazy' />
              </Link>
            </div>

            <div className='dashboard-header-menu-icon'>
              <Dropdown
                menu={{ items }}
                trigger={['click']}
                placement='bottomRight'
                overlayClassName='mobile-menu-toggle-list dashboard-header-menu'
              >
                <span onClick={(e) => e.preventDefault()}>
                  <Space>
                    <HiMenu />
                  </Space>
                </span>
              </Dropdown>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default DashboardHeader;
