import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AxiosInstance from '../../utils/axios';
// https://api.files-convert.com/api/file/verify-file-url
export const urlValidation = createAsyncThunk('urlValidation', async (data) => {
  try {
    const response = await AxiosInstance.post(`/file/verify-file-url`, data);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw error;
    }
  }
});

const urlValidationSlice = createSlice({
  name: 'urlValidation',
  initialState: {
    isLoading: false,
    urlValidationData: null,
    isError: false,
    errorMessage: ''
  },
  extraReducers: (builder) => {
    builder
      .addCase(urlValidation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(urlValidation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.urlValidationData = action.payload;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(urlValidation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.error.message;
      });
  }
});

export default urlValidationSlice.reducer;
