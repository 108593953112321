export const getProgressDetails = ({ percent, status, name, strings }) => {
  const isSuccess = percent === 100 && status === strings.SUCCESS;
  const isError = percent === 100 && status !== strings.SUCCESS && status !== strings.PENDING;
  const progressStatus = isSuccess ? strings.SUCCESS : isError ? 'exception' : 'active';
  const strokeColor = isSuccess ? '#52c41a' : isError ? 'red' : undefined;
  const animatedClass = status !== strings.SUCCESS && status !== strings.PENDING ? '' : 'progress-animated';
  const fileExtension = name?.split('.').pop();

  return {
    progressStatus,
    strokeColor,
    animatedClass,
    fileExtension
  };
};
