import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AxiosInstance from '../../utils/axios';

export const reconvertFiles = createAsyncThunk('file/reconvertFiles', async (files) => {
  try {
    const response = await AxiosInstance.post('file/reconvert-files', { files });
    return response.data; 
  } catch (error) {
    if (error.response) {
      throw error.response.data.message; 
    } else {
      throw error;
    }
  }
});

const reconvertFilesSlice = createSlice({
    name: 'reconvertFiles',
    initialState: {
      isLoading: false,
      reconvertData: null,
      isError: false,
      errorMessage: ''
    },
    extraReducers: (builder) => {
      builder
        .addCase(reconvertFiles.pending, (state) => {
          state.isLoading = true; 
        })
        .addCase(reconvertFiles.fulfilled, (state, action) => {
          state.isLoading = false; 
          state.reconvertData = action.payload; 
          state.isError = false; 
          state.errorMessage = ''; 
        })
        .addCase(reconvertFiles.rejected, (state, action) => {
          state.isLoading = false; 
          state.isError = true; 
          state.errorMessage = action.error.message;
        });
    }
  });
  
  export default reconvertFilesSlice.reducer;
  