import React, { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import AppLayout from './layouts';
import './scss/style.scss';
import './scss/rtl.scss';

import { useTranslation } from 'react-i18next';
import AppRoutes from './routes';
import { SessionProvider } from './context/SessionContext';

const App = () => {
  const cookies = useMemo(() => new Cookies(), []);
  const navigate = useNavigate();
  const location = useLocation();
  const { i18n } = useTranslation();

  useEffect(() => {
    const lang = cookies.get('lang') || 'en';
    const pathParts = location.pathname.split('/').filter((part) => part);

    if (pathParts.length === 0 || !['en', 'fr'].includes(pathParts[0])) {
      navigate(`/${lang}`);
    } else {
      const currentLang = pathParts[0];
      if (currentLang !== lang) {
        i18n.changeLanguage(currentLang);
        cookies.set('lang', currentLang, { path: '/', sameSite: true });
      }
    }
  }, [location.pathname, navigate, i18n, cookies]);

  return (
    <SessionProvider> 
    <React.StrictMode>
      <AppLayout>
        <AppRoutes />
      </AppLayout>
    </React.StrictMode>
    </SessionProvider> 
  );
};

export default App;
