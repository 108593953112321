// index of Dashboard code
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { fetchFiles } from '../../redux/slice/userListingSlice';
import DashboardComponent from './dashboard';
import { fetchFileMetadata } from '../../redux/slice/fileMetadataSlice';
import { removeFile } from '../../redux/slice/removeFileSlice';
import Notify from '../../components/common/notify';
import { reconvertFiles } from '../../redux/slice/reconvertFilesSlice';
import strings from "../../utils/strings.json"
const Dashboard = (props) => {
  const { data, loading, error, fetchFiles, callRemovefile, callFetchFileMetadata, callReconvertFile } = props;
  const [page, setPage] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [limit, setLimit] = useState(3);
  const [sort, setSort] = useState({ name: 1 });
  const [search, setSearch] = useState();
  const [metadata, setMetadata] = useState([]);
  const [isConvert, setisConvert] = useState(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    fetchFiles({ page, limit, sort, search });
    setisConvert(false);
  }, [page, limit, sort, fetchFiles, search, isConvert]);

  const handlePaginationChange = (newPage) => {
    setPage(newPage);
  };
  const handleSortChange = (field) => {
    setSort((prevSort) => ({ [field]: prevSort[field] === 1 ? -1 : 1 }));
    setPage(1);
  };
  const handleSearch = (searchValue) => {
    setSearch(searchValue);
  };
  const handleDeleteFile = async (deleteFiles) => {
    const deleteFilesResult = await callRemovefile(deleteFiles);
    const { type, error } = deleteFilesResult;
    if (type === 'file/remove/fulfilled') {
      Notify('success', strings.DELETE_FILE_SUCCESS_MSG, '');
      fetchFiles({ page, limit, sort, search });

    } else {
      Notify('error', error?.message, '');
    }
  };
  const fileMetadata = async (type) => {
    setLoading(true);
    const metadata = await callFetchFileMetadata(type);
    try {
      if (metadata.type === 'files/fetchFileMetadata/fulfilled') {
        setMetadata(metadata.payload);
      } else {
        console.log(metadata?.type?.message);
      }
    } catch (error) {
      console.log(error);
    }finally {
      setLoading(false);
    }
  };
  return (
    <DashboardComponent
      data={data}
      loading={loading}
      error={error}
      page={page}
      limit={limit}
      onPaginationChange={handlePaginationChange}
      onSortChange={handleSortChange}
      onSearch={handleSearch}
      search={search}
      onDeleteFiles={handleDeleteFile}
      onFileMetadata={fileMetadata}
      metadata={metadata}
      onReconvertFile={callReconvertFile}
      setisConvert={setisConvert}
      isLoading={isLoading}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.files.data,
    loading: state.files.loading,
    error: state.files.error,
    metadata: state.metaFiles.data
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchFiles: (params) => dispatch(fetchFiles(params)),
  callFetchFileMetadata: (data) => dispatch(fetchFileMetadata(data)),
  callRemovefile: (params) => dispatch(removeFile(params)),
  callReconvertFile: (data) => dispatch(reconvertFiles(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
