import React from 'react';
import { Modal as CommonModal } from 'antd';
import './modal.scss';

const Modal = ({ isOpen, onClose, children, closable, className = '', title = '', maskClosable = true, footer }) => {
  const modalClassName = `${className}`;
  return (
    <CommonModal
      centered
      title={title}
      open={isOpen}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      onCancel={onClose}
      closable={closable}
      className={modalClassName}
      maskClosable={maskClosable}
      footer={footer}
    >
      {children}
    </CommonModal>
  );
};

export default Modal;
