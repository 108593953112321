import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AxiosInstance from '../../utils/axios';
export const downloadFile = createAsyncThunk('downloadFile', async () => {
  try {
    const response = await AxiosInstance.get(`/file/user-files`);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw error;
    }
  }
});

const downloadFileSlice = createSlice({
  name: 'downloadFile',
  initialState: {
    isLoading: false,
    downloadFileData: null,
    isError: false,
    errorMessage: ''
  },
  extraReducers: (builder) => {
    builder
      .addCase(downloadFile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(downloadFile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.downloadFileData = action.payload;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(downloadFile.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.error.message;
      });
  }
});

export default downloadFileSlice.reducer;
