import React, { createContext, useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const SessionContext = createContext();

export const useSession = () => useContext(SessionContext);

export const SessionProvider = ({ children }) => {
  const [sessionId,setSessionId] = useState(uuidv4());
  const generateNewSessionId = () => {
    setSessionId(uuidv4()); // Generates a new session ID
  };
  return (
    <SessionContext.Provider value={{sessionId,generateNewSessionId}}>
      {children}
    </SessionContext.Provider>
  );
};
