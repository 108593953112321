import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getIPIfy = createAsyncThunk("getIPIfy", async () => {
  try {
    const response = await axios.get(`https://api.ipify.org?format=json`);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw error;
    }
  }
});

const getIPIfySlice = createSlice({
  name: "getIPIfy",
  initialState: {
    isLoading: false,
    getIPIfyData: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: (builder) => {
    builder
      .addCase(getIPIfy.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getIPIfy.fulfilled, (state, action) => {
        state.isLoading = false;
        state.getIPIfyData = action.payload;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(getIPIfy.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.error.message;
      });
  }
});

export default getIPIfySlice.reducer;
