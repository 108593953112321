import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AxiosInstance from '../../utils/axios';

export const removeFile = createAsyncThunk('file/remove', async (removeFileId) => {
  try {
    const response = await AxiosInstance.put(`file/remove`, { removeFileId });
    return response.data; 
  } catch (error) {
    if (error.response) {
      throw error.response.data.message; 
    } else {
      throw error;
    }
  }
});

const removeFileSlice = createSlice({
  name: 'removeFile',
  initialState: {
    isLoading: false,
    removeFileData: null,
    isError: false,
    errorMessage: ''
  },
  extraReducers: (builder) => {
    builder
      .addCase(removeFile.pending, (state) => {
        state.isLoading = true; 
      })
      .addCase(removeFile.fulfilled, (state, action) => {
        state.isLoading = false; 
        state.removeFileData = action.payload; 
        state.isError = false; 
        state.errorMessage = ''; 
      })
      .addCase(removeFile.rejected, (state, action) => {
        state.isLoading = false; 
        state.isError = true; 
        state.errorMessage = action.error.message; 
      });
  }
});

export default removeFileSlice.reducer;
