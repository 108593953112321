import React, { useEffect, useState } from 'react';
import FileConvertComponent from './filesConvert';
import { loadStripe } from '@stripe/stripe-js';
import { stripeLoadScript, driveClientId, driveDevelopmentKey } from '../../../../environment';
import Notify from '../../../common/notify';
import { appearance, currency } from '../../../../utils/commonUtils';
import { paymentIntent } from '../../../../redux/slice/paymentIntentSlice';
import { connect } from 'react-redux';
import { uploadFile } from '../../../../redux/slice/uploadFileSlice';
import useDrivePicker from 'react-google-drive-picker';
import { urlValidation } from '../../../../redux/slice/urlValidationSlice';
import { useSession } from '../../../../context/SessionContext';
import { saveAnalytics } from '../../../../redux/slice/saveAnalyticsSlice';
import { Cookies } from 'react-cookie';
import { v4 as uuidv4 } from 'uuid';
import strings from '../../../../utils/strings.json';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import ThankyouModal from '../../../modals/thankyouModal/thankyouModal';
import { convertFile } from '../../../../redux/slice/convertFileSlice';
import { downloadFile } from '../../../../redux/slice/downloadFileSlice';
import { fetchFileMetadata } from '../../../../redux/slice/fileMetadataSlice';

const stripePromise = loadStripe(stripeLoadScript);
const FileConvert = (props) => {
  const [isLoading, setLoading] = useState(false);
  const [isOpen, setisOpen] = useState(false);
  const [move, setMove] = useState(false);
  const [percent, setPercent] = useState(0);
  const [clientSecret, setClientSecret] = useState('');
  const [customer, setCustomer] = useState('');
  const [checkoutModalOpen, setCheckoutModalOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [openDropdowns, setOpenDropdowns] = useState({});
  const [openPicker, authResponse] = useDrivePicker();
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadInProgress, setUploadInProgress] = useState(false); // New flag to track upload state
  const [convertOptions, setConvertOptions] = useState({});
  const [validationErrors, setValidationErrors] = useState({}); // State for validation errors
  const [limitExtendMessage, setLimitExtendMessagee] = useState('');
  const {
    callpaymentIntent,
    callUploadFile,
    callurlValidation,
    callSaveAnalytics,
    ipData,
    callConvertFiles,
    callDownloadFiles,
    callFetchFileMetadata
  } = props;
  const cookies = new Cookies();
  const banIP = cookies.get('banned');
  const options = {
    clientSecret,
    appearance,
    locale: 'en'
  };
  const { sessionId } = useSession();
  const [conversionErrors, setConversionErrors] = useState({});
  const token = cookies.get('token');
  const [googleAccessToken, setGoogleAccessToken] = useState();
  const [isShowThankyouPopup, setShowThankyouPopup] = useState(false);
  const navigate = useNavigate();
  const lang = cookies.get('lang') || 'en';
  const [searchParams] = useSearchParams();
  const [navigateUrl, setNavigateUrl] = useState(null); // Track the URL for navigation
  const [metadata, setMetadata] = useState([]);
  const searchNumber = Object.fromEntries([...searchParams]);
  const keys = Object.keys(searchNumber);
  useEffect(() => {
    const interval = setInterval(() => {
      setPercent((prev) => {
        if (prev < 100) {
          return prev + 1;
        } else {
          clearInterval(interval);
          return 150;
        }
      });
    }, 150);

    return () => {
      clearInterval(interval);
      setMove(false);
    };
  }, []);

  useEffect(() => {
    const pendingFiles = selectedFiles.filter((file) => file.status === strings.PENDING);
    if (pendingFiles.length > 0 && !uploadInProgress) {
      setUploadInProgress(true);
      handleUpload(pendingFiles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFiles]);

  useEffect(() => {
    if (percent === 100) {
      setMove(false);
    }
  }, [percent]);
  useEffect(() => {
    // Trigger the navigation only after setting the modal state
    if (navigateUrl) {
      navigate(navigateUrl, { replace: true });
    }
  }, [navigateUrl, navigate]);
  const handleRegister = async () => {
    setLoading(true);

    if (validateConvertToSelection()) {
      const newFiles = selectedFiles
        .filter((item) => item.status === 'success')
        .map((item) => ({
          url: item.url,
          convertFrom: item.convertedFrom,
          convertTo: item.convertedTo?.toLowerCase(),
          size: item.file.size,
          fileConvertId: item.fileConvertId,
          ...(item.file.name && { fileOriginalName: item.file.name })
        }));

      const requestData = {
        sessionId: sessionId,
        files: newFiles
      };
      try {
        let result = await callSaveAnalytics(requestData);
        if (result?.type === 'saveAnalytics/fulfilled') {
          const intentdata = {
            amount: 50,
            currency: currency
          };
          if (result?.payload?.data?.success === false) {
            const errors = {};
            const filesToProcess = [...selectedFiles];
            result?.payload?.data.notAllowed.forEach(({ fileName, message }) => {
              const fileIndex = filesToProcess.findIndex((file) => file.file.name === fileName);
              if (fileIndex !== -1) {
                const fileWithError = filesToProcess[fileIndex];
                errors[fileWithError.id] = message;

                filesToProcess.splice(fileIndex, 1);
              }
            });
            setConversionErrors(errors);
          } else {
            setSelectedFiles([]);
            if (!token) {
              const paymentResult = await callpaymentIntent(intentdata);
              const { type, payload, error } = paymentResult || {};
              if (type === 'paymentIntent/fulfilled') {
                setCustomer(payload.data.customer);
                setClientSecret(payload.data.client_secret);
                setCheckoutModalOpen(true);
                if (keys.length === 0) {
                  // Set modal state first, then navigate
                  setCheckoutModalOpen(true);
                  setNavigateUrl(`/${lang}`); // Set URL for navigation after state update
                } else if (keys.includes('off')) {
                  const newParams = new URLSearchParams(searchParams);
                  newParams.delete('off');

                  if ([...newParams].length === 0) {
                    setNavigateUrl('/en/convert');
                  } else {
                    setNavigateUrl(`/${lang}/convert?${newParams.toString()}`);
                  }
                } else if (banIP) {
                  setNavigateUrl(`/${lang}`);
                }
              } else {
                Notify('error', error.message, '');
              }
            }
          }
        } else {
          const errors = {};

          result?.payload?.data.notAllowed.forEach(({ fileName, fileExtension, fileConvertTo, message }) => {
            const fileWithError = selectedFiles.find(
              (file) =>
                file.file.name === fileName &&
                file.file.extension === fileExtension &&
                file.convertedTo?.toLowerCase() === fileConvertTo?.toLowerCase()
            );

            if (fileWithError) {
              errors[fileWithError.id] = message;
            }
          });
          setConversionErrors(errors);
          Notify('error', result?.error.message, '');
        }
      } catch (error) {
        Notify('error', error.message, '');
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (authResponse?.access_token && selectedFile) {
      // Proceed with uploading the file when access token is available
      handleUploadFile(selectedFile, selectedFile.downloadLink);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authResponse, selectedFile]);

  useEffect(() => {
    if (authResponse?.access_token) {
      setGoogleAccessToken(authResponse.access_token);
    }
  }, [authResponse]);

  const handleOpenPicker = () => {
    openPicker({
      clientId: driveClientId?.replace(/['",]/g, ''),
      developerKey: driveDevelopmentKey?.replace(/['",]/g, ''),
      viewId: 'DOCS',
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      token: googleAccessToken,

      multiselect: false,
      callbackFunction: (data) => {
        if (data && data.action === 'cancel') {
          return;
        }

        if (data && data.docs && data.docs.length > 0) {
          const file = data.docs[0];
          const downloadLink = `https://www.googleapis.com/drive/v3/files/${file.id}?alt=media`;
          setSelectedFile({
            ...file,
            downloadLink
          });
        } else {
          console.log('No file selected or invalid response');
        }
      }
    });
  };
  const handleUploadFile = async (file, downloadLink) => {
    try {
      const response = await fetch(downloadLink, {
        headers: {
          Authorization: `Bearer ${googleAccessToken}`
        }
      });
      const blob = await response.blob();
      const formData = new FormData();
      // Convert blob to File object for further upload processing
      const fileToUpload = new File([blob], file.name, { type: blob.type });
      formData.append('file', fileToUpload);
      formData.append('sessionId', sessionId);
      const result = await callUploadFile(formData);
      let simulatedPercent = 0;
      const interval = setInterval(() => {
        setSelectedFiles((prevFiles) =>
          prevFiles.map((f) =>
            f.id === file.id && simulatedPercent < 95 ? { ...f, percent: (simulatedPercent += 5) } : f
          )
        );
      }, 200);

      const { type: uploadType, payload: uploadPayload, error: uploadError } = result || {};

      if (uploadType === 'uploadFile/fulfilled' && uploadPayload.success) {
        clearInterval(interval);
        Notify('success', uploadPayload.message, '');
        let { fileOriginalName, size, _id } = uploadPayload.data;
        let data = {
          id: _id,
          file: { name: fileOriginalName, size: size },
          status: strings.SUCCESS,
          percent: 100
        };
        setSelectedFiles((prevFiles) => [...prevFiles, data]);
      } else {
        Notify('error', uploadError.message, '');
      }
    } catch (error) {
      console.log('error.message');
    }
  };

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files).map((file) => {
      const status = file.size > 20 * 1024 * 1024 ? strings.FILE_SIZE_EXCEEDS_20_MB : strings.PENDING;

      return {
        file,
        id: uuidv4(),
        status,
        percent: status === strings.PENDING ? 0 : 100
      };
    });

    setSelectedFiles((prevFiles) => {
      const totalFiles = prevFiles.length + newFiles.length;

      if (totalFiles > 20) {
        setLimitExtendMessagee(strings.FILE_LIMIT_ERROR_MESSSGE);
        const availableSlots = 20 - prevFiles.length;
        return [...prevFiles, ...newFiles.slice(0, availableSlots)];
      }

      setLimitExtendMessagee('');
      return [...prevFiles, ...newFiles];
    });

    event.target.value = null; // Reset the file input
  };

  const handleDeleteFile = (fileId) => {
    setLimitExtendMessagee('');
    setSelectedFiles((prevFiles) => prevFiles.filter((fileObj) => fileObj.id !== fileId));
  };

  const uploadBrowsedFile = async (fileObj) => {
    const formData = new FormData();
    const blob = new Blob([fileObj.file], { type: fileObj.file.type });
    formData.append('file', blob, fileObj.file.name);
    formData.append('sessionId', sessionId);
    let simulatedPercent = 0;
    const interval = setInterval(() => {
      setSelectedFiles((prevFiles) =>
        prevFiles.map((f) =>
          f.id === fileObj.id && simulatedPercent < 95 ? { ...f, percent: (simulatedPercent += 5) } : f
        )
      );
    }, 200);

    try {
      const result = await callUploadFile(formData);
      if (result?.type === 'uploadFile/fulfilled') {
        clearInterval(interval); // Clear interval on success
        setSelectedFiles((prevFiles) =>
          prevFiles.map((f) =>
            f.id === fileObj.id
              ? {
                  ...f,
                  status: strings.SUCCESS,
                  percent: 100,
                  url: result?.payload?.data?.url,
                  convertedFrom: fileObj?.file?.name?.split('.').pop(),
                  convertedTo: fileObj?.convertedTo?.toLowerCase(),
                  fileConvertId: result?.payload?.data?._id
                } // Set to 100% when upload succeeds
              : f
          )
        );
      } else {
        clearInterval(interval);
        setSelectedFiles((prevFiles) =>
          prevFiles.map((f) =>
            f.id === fileObj.id
              ? { ...f, status: result?.error?.message || 'failed', percent: 100 } // Reset percent on error
              : f
          )
        );
      }
    } catch (error) {
      clearInterval(interval);
      setSelectedFiles((prevFiles) =>
        prevFiles.map((f) => (f.id === fileObj.id ? { ...f, status: 'failed', percent: 100 } : f))
      );
    }
  };

  const handleUpload = async (pendingFiles) => {
    for (const fileObj of pendingFiles) {
      await uploadBrowsedFile(fileObj);
    }
    setUploadInProgress(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    handleFileChange({ target: { files } }); // Pass the files to handleFileChange
  };

  const handleDragOver = (event) => {
    event.preventDefault(); // Prevent default to allow drop
  };

  const handleGetURL = async (url, onClose, setError) => {
    try {
      const convertFileResult = await callurlValidation({ url });
      const { type: validationType, payload: validationPayload, error: validationError } = convertFileResult || {};
      if (validationType === 'urlValidation/fulfilled') {
        const { filename, size, url: validatedUrl } = validationPayload.data;
        const formData = new FormData();
        formData.append('fileUrl', validatedUrl);
        formData.append('category', 'publicUrl');
        formData.append('sessionId', sessionId);
        formData.append('size', size);
        formData.append('name', filename);
        const uploadResult = await callUploadFile(formData);
        const { type: uploadType, payload: uploadPayload, error: uploadError } = uploadResult || {};
        if (uploadType === 'uploadFile/fulfilled' && uploadPayload.success) {
          Notify('success', uploadPayload.message, '');
          let { sessionId, fileOriginalName, size } = uploadPayload.data;
          let data = {
            id: sessionId,
            file: { name: fileOriginalName, size: size },
            status: strings.SUCCESS,
            percent: '100'
          };
          setSelectedFiles((prevFiles) => [...prevFiles, data]);
          onClose(); // Close the modal only after successful upload
        } else {
          setError(uploadError.message);
        }
      } else {
        setError(validationError.message);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const validateConvertToSelection = () => {
    setLoading(false);

    const errors = {};
    selectedFiles.forEach((file) => {
      if (file.status === strings.SUCCESS && !convertOptions[file.id]) {
        errors[file.id] = 'Required';
      }
    });
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSelectConvertTo = (fileId, convertToOption) => {
    setConvertOptions((prev) => ({
      ...prev,
      [fileId]: convertToOption
    }));
    setValidationErrors((prev) => {
      const newErrors = { ...prev };
      delete newErrors[fileId];
      return newErrors;
    });
    const updatedFiles = selectedFiles.map((file) => {
      if (file.id === fileId) {
        return { ...file, convertedTo: convertToOption?.toLowerCase() };
      }
      return file;
    });

    setSelectedFiles(updatedFiles);
    setOpenDropdowns((prevState) => ({
      ...prevState,
      [fileId]: false
    }));
  };

  const handleSuccess = async (files) => {
    if (files.length === 0) {
      return;
    }
    for (let file of files) {
      await getDropboxSelectedFile(file);
    }
  };

  const getDropboxSelectedFile = async (file) => {
    setLoading(true);
    try {
      const downloadResponse = await fetch(file.link);
      const blob = await downloadResponse.blob();
      const fileToUpload = new File([blob], file.name, { type: blob.type });
      const formData = new FormData();
      formData.append('file', fileToUpload);
      formData.append('sessionId', sessionId);
      const result = await callUploadFile(formData);
      const { type: uploadType, payload: uploadPayload, error: uploadError } = result || {};

      if (uploadType === 'uploadFile/fulfilled' && uploadPayload.success) {
        Notify('success', uploadPayload.message, '');
        let { fileOriginalName, size, _id } = uploadPayload.data;
        let data = {
          id: _id,
          file: { name: fileOriginalName, size: size },
          status: 'success',
          percent: '100'
        };
        setSelectedFiles((prevFiles) => [...prevFiles, data]);
      } else {
        Notify('error', uploadError.message, '');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setisOpen(false);
    }
  };

  const handleConvertFiles = async (url, onClose, setError) => {
    try {
      const convertFilesResult = await callConvertFiles({ sessionId: sessionId });
      const { type: validationType, error: validationError } = convertFilesResult || {};
      setLoading(true);
      if (validationType === 'convertFile/fulfilled') {
        setTimeout(() => {
          handleDownloadFiles();
        }, 6000);
      } else {
        setError(validationError.message);
      }
    } catch (error) {
      setError(error.message);
    }
  };
  const handleDownloadFiles = async (url, onClose, setError) => {
    setLoading(false);
    try {
      const downloadFilesResult = await callDownloadFiles(url);
      const { type: validationType, payload, error: validationError } = downloadFilesResult || {};
      const { data } = payload || {};
      const { files } = data || {};
      if (validationType === 'downloadFile/fulfilled') {
        Notify('success', 'Download successful', '');
        const exportFileUrls = files
          .filter((file) => file.conversionStatus === 'SUCCESS' && file.exportFileUrl)
          .map((file) => file.exportFileUrl);
        await downloadAllFiles(exportFileUrls);
        navigate(`/${lang}/dashboard`); // Navigate after all files are downloaded
      } else {
        setError(validationError.message);
      }
    } catch (error) {
      setError(error?.message || 'Error');
    }
  };

  const downloadAllFiles = (urls) => {
    return Promise.all(
      urls.map(
        (url, index) =>
          new Promise((resolve) => {
            setTimeout(() => {
              const a = document.createElement('a');
              a.href = url;
              a.download = url.split('/').pop();
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
              resolve();
            }, index * 1000);
          })
      )
    );
  };

  const fileConvertData = async (type) => {
    setLoading(true);
    const metadata = await callFetchFileMetadata(type);
    try {
      if (metadata.type === 'files/fetchFileMetadata/fulfilled') {
        setMetadata(metadata.payload);
      }
    } catch (error) {
      console.log(error);
    }finally {
      setLoading(false); 
    }
  };

  return (
    <>
      <FileConvertComponent
        move={move}
        setLoading={setLoading}
        isLoading={isLoading}
        percent={percent}
        setisOpen={setisOpen}
        isOpen={isOpen}
        handleRegister={handleRegister}
        checkoutModalOpen={checkoutModalOpen}
        clientSecret={clientSecret}
        options={options}
        stripePromise={stripePromise}
        customer={customer}
        setCheckoutModalOpen={setCheckoutModalOpen}
        handleOpenPicker={handleOpenPicker}
        handleUpload={handleUpload}
        setOpenDropdowns={setOpenDropdowns}
        openDropdowns={openDropdowns}
        selectedFiles={selectedFiles}
        handleFileChange={handleFileChange}
        handleDeleteFile={handleDeleteFile}
        handleDrop={handleDrop}
        handleDragOver={handleDragOver}
        handleGetURL={handleGetURL}
        setSelectedFiles={setSelectedFiles}
        validationErrors={validationErrors}
        handleSelectConvertTo={handleSelectConvertTo}
        limitExtendMessage={limitExtendMessage}
        handleSuccess={handleSuccess}
        conversionErrors={conversionErrors}
        setConversionErrors={setConversionErrors}
        setShowThankyouPopup={setShowThankyouPopup}
        ipData={ipData}
        onFileMetadata={fileConvertData}
        metadata={metadata}
      />
      {isShowThankyouPopup && (
        <ThankyouModal visible={isShowThankyouPopup} handleConvertFiles={handleConvertFiles} isLoading={isLoading} />
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    callpaymentIntent: (data) => dispatch(paymentIntent(data)),
    callUploadFile: (data) => dispatch(uploadFile(data)),
    callurlValidation: (data) => dispatch(urlValidation(data)),
    callSaveAnalytics: (data) => dispatch(saveAnalytics(data)),
    callConvertFiles: (data) => dispatch(convertFile(data)),
    callDownloadFiles: (data) => dispatch(downloadFile(data)),
    callFetchFileMetadata: (data) => dispatch(fetchFileMetadata(data))
  };
};

export default connect(null, mapDispatchToProps)(FileConvert);
