import React, { useEffect } from 'react';
import './account.scss';
import countryList from 'react-select-country-list';
import { formatDate } from '../../utils/commonUtils';
const AccountComponent = ({ userDetails, handleChangeDetails, handleUpdateProfile, detailsErrors, authenticate }) => {
  const countryOptions = countryList().getData();
  const { email, createdAt } = authenticate?.data || {};

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);

  useEffect(() => {
    const selectedCountry = countryOptions.find((option) => option.label === userDetails.country);
    if (selectedCountry) {
      handleChangeDetails({ target: { name: 'country', value: selectedCountry.value } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails.country, countryOptions]);

  return (
    <>
      <div className='main_root'>
        <div className='wpb_info_wrapper'>
          <div className='main_container'>
            <div className='mt-50 wpb_content_account account_bg_wrap'>
              <div className='account_title'>
                <h2>Account Information</h2>
              </div>
              <div className='account_info_inner'>
                <div className='wi-50 account_address'>
                  <strong>Email Address</strong>
                  <p>
                    <a>{email} </a>
                  </p>
                </div>
                <div className='wi-50 account_address'>
                  <strong>MEMBER SINCE</strong>
                  <p>
                    <time timestamp={createdAt}>{createdAt && formatDate(createdAt)}</time>
                  </p>
                </div>
              </div>
            </div>
            <div className='wpb_content_billing account_bg_wrap'>
              <div className='account_title'>
                <h2>Billing Information</h2>
              </div>
              <div className='form_group_warp'>
                <form className='ant-form ant-form-horizontal css-mzwlov'>
                  <div className='account-container'>
                    <div className='field-account'>
                      <label className='lbl_wpb_wrapper' for='first-name'>
                        First Name <span className='required-field'>*</span>
                      </label>
                      <input
                        id='first-name'
                        type='text'
                        name='firstName'
                        placeholder='First Name'
                        class='ant-input css-mzwlov ant-input-outlined ant-input-prefix '
                        onChange={(e) => handleChangeDetails(e)}
                        value={userDetails.firstName}
                        maxLength={39}
                      />
                      {detailsErrors.firstName && <p className='errors'>{detailsErrors.firstName}</p>}
                    </div>
                    <div className='field-account'>
                      <label className='lbl_wpb_wrapper' for='last-name'>
                        Last Name <span className='required-field'>*</span>
                      </label>
                      <input
                        id='last-name'
                        type='text'
                        inputmode='text'
                        name='lastName'
                        placeholder='Last Name'
                        class='ant-input css-mzwlov ant-input-outlined ant-input-prefix '
                        onChange={(e) => handleChangeDetails(e)}
                        value={userDetails.lastName}
                        maxLength={39}
                      />
                      {detailsErrors.lastName && <p className='errors'>{detailsErrors.lastName}</p>}
                    </div>
                    <div className='field-account address'>
                      <label className='lbl_wpb_wrapper' for='street-address'>
                        Street Address <span className='required-field'>*</span>
                      </label>
                      <input
                        id='street-address'
                        type='text'
                        name='streetAddress'
                        inputmode='text'
                        placeholder='Los Angeles, 9 Stonehedge Dr S'
                        class='ant-input css-mzwlov ant-input-outlined ant-input-prefix '
                        onChange={(e) => handleChangeDetails(e)}
                        value={userDetails.streetAddress}
                        maxLength={255}
                      />
                      {detailsErrors.streetAddress && <p className='errors'>{detailsErrors.streetAddress}</p>}
                    </div>
                    <div className='field-account'>
                      <label className='lbl_wpb_wrapper' for='country'>
                        Country <span className='required-field'>*</span>{' '}
                      </label>
                      <select
                        class='ant-input-prefix'
                        name='country'
                        onChange={(e) => handleChangeDetails(e)}
                        value={userDetails.country}
                      >
                        <option value=''>Select Your Country</option>
                        {countryOptions?.map((item) => {
                          return (
                            <option key={item.value} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })}
                      </select>
                      {detailsErrors.country && <p className='errors'>{detailsErrors.country}</p>}
                    </div>
                    <div className='field-account'>
                      <label className='lbl_wpb_wrapper' for='city'>
                        City <span className='required-field'>*</span>{' '}
                      </label>
                      <input
                        id='city'
                        type='text'
                        name='city'
                        inputmode='text'
                        placeholder='Los Angeles'
                        class='ant-input css-mzwlov ant-input-outlined ant-input-prefix'
                        onChange={(e) => handleChangeDetails(e)}
                        value={userDetails.city}
                        maxLength={39}
                      />
                      {detailsErrors.city && <p className='errors'>{detailsErrors.city}</p>}
                    </div>
                    <div className='field-account'>
                      <label className='lbl_wpb_wrapper' for='postal-code'>
                        Postal Code
                        <span className='required-field'>*</span>
                      </label>
                      <input
                        id='postal-code'
                        name='postalCode'
                        inputmode='text'
                        placeholder='90077'
                        class='ant-input css-mzwlov ant-input-outlined ant-input-prefix'
                        type='text'
                        onChange={(e) => handleChangeDetails(e)}
                        value={userDetails.postalCode}
                        maxLength={39}
                      />
                      {detailsErrors.postalCode && <p className='errors'>{detailsErrors.postalCode}</p>}
                    </div>
                  </div>
                  <button class='payment__account' type='button' onClick={handleUpdateProfile}>
                    Save
                  </button>
                </form>
              </div>
            </div>
            <div className='wpb_content_subs account_bg_wrap'>
              <div className='account_title'>
                <h2>Subscriptions</h2>
              </div>
              <div className='subscriptions_info_wrap'>
                <span>Phone Tracking</span>
                <button>Cancel Subscription</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountComponent;
