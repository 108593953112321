import { Spin } from 'antd';
import './thankyouModal.scss';
import { Home } from '../../../pages';
import strings from '../../../utils/strings.json';
import thankyouAnimation from '../../animations/thankyouAnimation.json';
import Lottie from 'react-lottie';
import Modal from '../../common/Modal/modal';

const ThankyouModal = ({ visible, handleConvertFiles, isLoading }) => {
  return (
    <div>
      <Home />
      <Modal isOpen={visible} className='thankyou-info-wrap' maskClosable={false} closeIcon={false}>
        <Spin spinning={isLoading}>
          <div className='thankyou-modal-section'>
            <div className='thankyou-modal-title'>
              <span> {strings.YOUR_FILE_IS_READY_FOR_DOWNLOAD} </span>
            </div>
            <div className='wrap-top-modal'>
              <div className='body-modal'>
                <div className='modal-thankyou-form'>
                  <div className='mb'>
                    <Lottie options={{ animationData: thankyouAnimation }} animation height={220} width={220} />
                  </div>
                </div>
                <div className='submit-btn-modal'>
                  <h1>{strings.THANK_YOU}</h1>
                </div>
                <div>{strings.THANK_YOU_POPUP_INFORMATION_MESSAGE}</div>
                <div className='submit-btn-modal'>
                  <button className='hl_cta_wrap' onClick={handleConvertFiles}>
                    {strings.DOWNLOAD_YOUR_FILE}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </Modal>
    </div>
  );
};

export default ThankyouModal;
